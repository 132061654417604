import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO />
    <div className="warning-box">
      <p>Please note, that it's still under development. Beta will come shortly.</p>
    </div>
    <h1>slug.design</h1>
    <p>Welcome to a slug.design documentation website.</p>
    <h2>What is that?</h2>
    <p>The slug.design is a design system made for AgentSlug.com websites.</p>
    <p>It contains <strong>SASS framework</strong> and <strong>React components library.</strong></p>
    <h2>Versioning</h2>
    <p>Both `@eskalacja/as-sass` and `@eskalacja/as-react` follow semver versioning for everything that is documentated on this page.</p>
    <h3>Semver</h3>
    <p>Semver version contains of three numbers: [major].[minor].[patch]</p>
    <p>In order to follow semver we promise to publish:</p>
    <ul>
      <li>patch versions that contain only bug fixes and no functionality changes</li>
      <li>minor version that contain functionality changes which are backwards compatible</li>
      <li>major version that can break your code when updated</li>
    </ul>
  </Layout>
);

export default IndexPage;
